import { Env } from '@app/env';

export const environment: Env = {
  production: false,
  name: 'dev',
  region: 'us-east-1',
  version: '4.2.1',

  defaultOffsetFromUtc: 8,
  fixedDateMs: '2022-05-18T00:00:00Z',

  shortDomain: 'bscp.io',
  publicBucket: 'brightscrip-public-dev',
  patientsBucket: 'brightscrip-patients-dev',

  captchaApiKey:
    'h7ZJVXbRfJiAZ5mnEetMxZPxZG6bwxwhVg+Cekr4ZlAk/UOcIxmWIX/tGJJicbuya7xQ0B+5d7bL7GEo6pLDeJNORoRH7zaIJhzwwzxDTOhS0mRfG2WGU7MAEj/FoiNYmUp/Q3I0RkOrPDbG+vL0JIE93N3Y+q+LMKLRbLOshTqtmxkhEsxYqAH9huu50PoJi/sQZ5QclHaW45MzQxzJ8OK6M+lzvc7gdYzjkRp1jLz4tykkzH2wc5hSoqVde8iuLAqWW1x6LHTIqyPS8atbTMZgSjjFKj/NbDK4qKwDpDW6lHKPJuO8oGJe3rO8YsN9DxHYqhlkmWVA+ABkLhVbtebzcWVXsWK//EbFNu6+/KzB59JGiVvpXULX8dk+eK4lkxTY1Zx5nXAWsWhQbeWygeg45D014tIFeVMybOX0VFEwcrqGpyqoUwr77w+dTfSfVS+84CzEkRVCOlpAAHOWqiIN/THZfWBq8fVBw3YeAoCCXBrweGz1ppIRV0bmhTiLZp/tVmhVfGzSC9P/RS4kdtp0loqTc4KFS8aCCLQuz4cQzcDHqc95SbxkZwxsFm518tPnzQBRmB4Hq8Pigk5cApXunqYKnVFOqJOkRekTFjyjt5Dboe5nvC79ojlAuACyKQCayeu0FPXWldyJoSl0xyoVgkqXVJQKMYgsHosMYEU=_0_1',
  captchaUrl: 'https://7bbf66d2de7e.us-east-1.captcha-sdk.awswaf.com/7bbf66d2de7e/jsapi.js',
  captchaEnabled: true,
  mixPanel: {
    enabled: false,
    token: 'f425546f3ef44ea7625c6badf6cecc12',
  },
  growthbook: {
    clientKey: 'sdk-2zbu8gURHFjFnCkA',
    enabledDevMode: false,
  },
  posthog: {
    enabled: false,
    token: 'phc_zMpofrUg3ogICugsgaUswZeB2xAUwFYRArBKgqUP9tq',
    host: 'https://app.posthog.com',
  },
  sentry: {
    enabled: true,
    dsn: 'https://3b3e923fb30c4e4098f149fb7ee4bc5e@o282441.ingest.sentry.io/6567854',
  },

  cognito: {
    userpoolId: 'us-east-1_8IsnKZ7pg',
    appClientId: '7u365q4fg0ien0qm5f2t5t8f9',
  },

  graphql: {
    endpoint: 'https://3qxr7olukfcllon7cqblsfji6q.appsync-api.us-east-1.amazonaws.com/graphql',
    region: 'us-east-1',
  },

  openSearchUrl: 'https://98xcg6t3ue.execute-api.us-east-1.amazonaws.com/dev/proxy',

  codeMainUrl: 'https://code-maint-api-dev.monarchsp.com/dev',
  codeRequestUrl: 'https://copay-code-requests-api-dev.monarchsp.com/dev',
  codeRequestAggregatesUrl: 'https://code-request-aggregates-api-dev.monarchsp.com/dev',
  dataDeliveryUrl: 'https://data-delivery-api-dev.monarchsp.com/dev',
  paymentUrl: 'https://sbgredeq3e.execute-api.us-east-1.amazonaws.com/dev',
  devOpsUrl: 'https://w1p260trrb.execute-api.us-east-1.amazonaws.com/dev',
  documentsUrl: 'https://fa2n3jj8zg.execute-api.us-east-1.amazonaws.com/dev',
  etlUrl: 'https://etl-api-dev.monarchsp.com/dev',
  faxUrl: 'https://hey5w5a8of.execute-api.us-east-1.amazonaws.com/dev',
  identityUrl: 'https://identity-api-dev.monarchsp.com/dev',
  inboundsCallsUrl: 'https://inbound-calls-api-dev.monarchsp.com/dev',
  notificationUrl: 'https://notifications-api-dev.monarchsp.com/dev',
  pharmacyUrl: 'https://pharmacies-api-dev.monarchsp.com/dev',
  prescriberUrl: 'https://prescribers-api-dev.monarchsp.com/dev',
  rxConnectUrl: 'https://rxconnect-api-dev.monarchsp.com/dev',
  sftpUrl: 'https://ik08j3ke18.execute-api.us-east-1.amazonaws.com/dev',
  supportUrl: 'https://whvchy321e.execute-api.us-east-1.amazonaws.com/dev',
  surveillanceUrl: 'https://surveillance-api-dev.monarchsp.com/dev',

  aggregatesUrl: 'https://ypxayjch8a.execute-api.us-east-1.amazonaws.com/dev',
  copayAssistanceServiceUrl: 'https://okmsl8i4b3.execute-api.us-east-1.amazonaws.com/dev',
  copayUrl: 'https://marshc9vde.execute-api.us-east-1.amazonaws.com/dev',
  masterDataUrl: 'https://gz0co9wijj.execute-api.us-east-1.amazonaws.com/dev',
  paUrl: 'https://kx79ymp4gl.execute-api.us-east-1.amazonaws.com/dev',
  rxTriageServiceUrl: 'https://zn3tbyckei.execute-api.us-east-1.amazonaws.com/dev',
  tableEditorServiceUrl: 'https://aiq6itme7h.execute-api.us-east-1.amazonaws.com/dev',
  tokenActionUrl: 'https://uymz24qdxe.execute-api.us-east-1.amazonaws.com/dev',
  triageDeltaServiceUrl: 'https://s0y34y8yjc.execute-api.us-east-1.amazonaws.com/dev',
};
