import { Injectable, isDevMode } from '@angular/core';
import config from '@config';
import { UIAnalyticsEventKey } from '@services/models/ui-analytics-event-key.model';
import { TenantIdType } from '@shared/models/tenant-id.type';
import { IUser } from '@shared/models/user.model';
import mixpanel, { OverridedMixpanel } from 'mixpanel-browser';
import posthog, { PostHog } from 'posthog-js';

@Injectable({ providedIn: 'root' })
export class UIAnalyticsService {
  private currentUser?: IUser;
  private currentTenantId?: string;
  private mixpanel?: OverridedMixpanel;
  private posthog?: PostHog;

  constructor() {
    this.init();
  }

  init() {
    if (config.mixPanel.enabled) {
      mixpanel.init(config.mixPanel.token, { debug: isDevMode() });
      this.mixpanel = mixpanel;
    }
    if (config.posthog.enabled) {
      posthog.init(config.posthog.token, { api_host: config.posthog.host });
      this.posthog = posthog;
    }
  }

  private track(eventKey: UIAnalyticsEventKey, properties: Record<string, unknown> = {}) {
    properties.user = this.currentUser;
    properties.tenantId = properties.tenantId ?? this.currentTenantId;
    this.mixpanel?.track(eventKey, properties);
    this.posthog?.capture(eventKey, properties);
  }

  trackLogin(user: IUser) {
    this.currentUser = user;
    this.mixpanel?.identify(user.username);
    this.mixpanel?.people.set({ Email: user.email, Role: user.role });
    if (user.tenants) this.mixpanel?.set_group('Tenant', user.tenants ?? []);

    this.posthog?.identify(user.username, {
      email: user.email,
      name: `${user.first_name} ${user.last_name}`,
      role: user.role,
      tenants: user.tenants,
    });

    this.track(UIAnalyticsEventKey.LOG_IN);
  }

  trackTenantSwitch(tenantId: TenantIdType) {
    this.currentTenantId = tenantId;
    this.track(UIAnalyticsEventKey.TENANT_SWITCH, { tenantId });
  }

  trackLogout() {
    this.currentUser = undefined;
    this.currentTenantId = undefined;
    this.track(UIAnalyticsEventKey.LOG_OUT);
  }

  trackRedirect(url: string) {
    this.track(UIAnalyticsEventKey.URL_CHANGED, { url });
  }
}
