import { Location } from '@angular/common';
import { Injectable, inject } from '@angular/core';
import config from '@config';
import { CouponRequestStateService } from '@services/coupon-request-state.service';

@Injectable({
  providedIn: 'root',
})
export class UrlService {
  TOKEN_ACTION_ROUTES = '/ta/';
  PHARMACY_URL = `${config.pharmacyUrl}/pharmacies/{tenant}`;
  PHARMACY_SUGGEST_URL = `${config.pharmacyUrl}/pharmacies-suggest/{tenant}`;
  PHARMACY_EMAIL_INSTRUCTIONS_URL = `${config.pharmacyUrl}/email_pharmacy_software_instructions/{tenant}`;
  COPAY_CODE_REQUESTS_URL = `${config.codeRequestUrl}/code_requests/{tenant}`;
  COPAY_CODE_REQUESTS_COUPON_URL = `${config.codeRequestUrl}/coupons`;
  COPAY_SCORECARD_URL = `${config.codeRequestUrl}/code-request-aggregates/{tenant}/scorecard`;
  COPAY_ACTIONS_URL = `${config.codeRequestUrl}/ccr_action/{tenant}`;
  USER_URL = `${config.identityUrl}/users`;
  ROLES_URL = `${config.identityUrl}/roles`;
  FORGOT_PASSWORD_URL = `${config.identityUrl}/users/forgot_password`;
  CONFIRM_NEW_PASSWORD_URL = `${config.identityUrl}/users/confirm_new_password`;
  CHANGE_PASSWORD_URL = `${config.identityUrl}/users/change_password`;
  RULES_EXTRACT_URL = `${config.dataDeliveryUrl}/data-sftp/{tenant}`;
  PERFORMANCE_AGGREGATES_URL = `${config.codeRequestAggregatesUrl}/get-performance/{tenant}`;
  GROWTH_URL = `${config.codeRequestAggregatesUrl}/get-growth/{tenant}`;
  PRESCRIBER_URL = `${config.prescriberUrl}/prescribers`;
  NOTIFICATION_URL = `${config.notificationUrl}/notification/{tenant}`;
  COUPON_CODES_URL = `${config.codeMainUrl}/codes/{tenant}`;
  VERSION_URL = `${config.identityUrl}/version`;
  TOKENS_URL = `${config.identityUrl}/tokens`;
  SECRET_URL = `${config.identityUrl}/secret`;
  SURVEILLANCE_SETTINGS_URL = `${config.surveillanceUrl}/surveillance/{tenant}`;
  RULES_USAGE_URL = `${config.surveillanceUrl}/surveillance-rules-usage/{tenant}`;
  PROGRAM_URL = `${config.pharmacyUrl}/programs`;
  PROGRAM_GROUP_URL = `${config.pharmacyUrl}/groups/{tenant}`;
  PROGRAM_NO_AUTH_URL = `${config.pharmacyUrl}/pharmacies-programs-no-auth/{tenant}`;
  PHARMACY_PROGRAM_URL = `${config.pharmacyUrl}/pharmacies-programs/{tenant}`;
  NETWORK_GROUP_URL = `${config.pharmacyUrl}/network-groups/{tenant}`;
  CODES_BUCKETS_URL = `${config.codeMainUrl}/get-performance/{tenant}`;
  CODES_LIMITS_URL = `${config.pharmacyUrl}/codes-limits/{tenant}`;
  ETL_URL = `${config.etlUrl}/`;
  ETL_IMPORT_FILE_URL = `${config.etlUrl}/upload-file/{tenant}`;
  ETL_MASTER_TABLE_URL = `${config.etlUrl}/master_table/{tenant}`;
  DATA_DELIVERY_URL = `${config.dataDeliveryUrl}/`;
  DATA_DELIVERY_DOWNLOAD_URL = `${config.dataDeliveryUrl}/data-download/{tenant}`;
  DATA_DELIVERY_DOWNLOAD_ASYNC_URL = `${config.dataDeliveryUrl}/data-download-async/{tenant}`;
  PAYMENT_URL = `${config.paymentUrl}/`;
  FTP_ACCOUNTS_URL = `${config.sftpUrl}/ftp_accounts/{tenant}`;
  RX_TRIAGE_SERVICE_URL = `${config.rxTriageServiceUrl}/`;
  TRIAGE_DELTA_SERVICE_URL = `${config.triageDeltaServiceUrl}/`;
  COPAY_ASSISTANCE_SERVICE_URL = `${config.copayAssistanceServiceUrl}/`;
  TABLE_EDITOR_SERVICE_URL = `${config.tableEditorServiceUrl}/`;
  RX_CONNECT_SERVICE = `${config.rxConnectUrl}/get-dashboard-data/{tenant}`;
  RX_CONNECT_EXPORT = `${config.rxConnectUrl}/export-data/{tenant}`;
  RX_CONNECT_TOP_DATA = `${config.rxConnectUrl}/get-top-data/{tenant}`;
  INBOUND_CALLS_URL = `${config.inboundsCallsUrl}/calls/{tenant}`;
  INBOUND_CALLS_SEARCH_URL = `${config.inboundsCallsUrl}/calls-search/{tenant}`;
  SUPPORT_REQUESTS_URL = `${config.supportUrl}/support_requests`;
  MASTER_DATA_URL = config.masterDataUrl;
  PA_SERVICE = config.paUrl;
  PA_SERVICE_LOGIN_URL = `${config.paUrl}/login-patient/{token}`;
  LTF_SERVICE_LOGIN_URL = `${config.paUrl}/ltf/login-patient/{token}`;
  PATIENT_ACTION_LOGIN_URL = `${config.tokenActionUrl}/ta/{token}`;
  PATIENT_ACTION_PROCESS_URL = `${config.tokenActionUrl}/login-patient/{token}/actions/{action}`;
  TOKEN_ACTION_CREATE_URL = `${config.tokenActionUrl}/ta`;
  LOOKUPS_URL = `${config.masterDataUrl}/settings/{subject}/{attribute}`;
  LOOKUPS_LIST_URL = `${config.masterDataUrl}/settings/{subject}`;
  AGGREGATES_URL = `${config.aggregatesUrl}/`;
  FAX_SERVICE = `${config.faxUrl}/outboundfax/{tenant}`;
  DOCUMENT_SERVICE_URL = `${config.documentsUrl}/form/{tenant}`;
  DEV_OPS_SERVICE_BASE_URL = config.devOpsUrl;
  CONDITION_EXPRESSION_RESOLVER_URL = `${config.copayUrl}/resolve-condition-expression`;
  CLIENT_IP_URL = `${config.masterDataUrl}/client/ip`;

  readonly NON_AUTH_ROUTES = ['/version'];
  readonly location = inject(Location);

  isLogin() {
    return this.location.path().startsWith('/login') || this.location.path().startsWith('/agreement');
  }

  isRoot() {
    return this.location.path() === '';
  }

  isTokenLogin(): boolean {
    const url = window.location.href.toLowerCase();
    return (
      url.includes('retail/coupon-request?token=') ||
      url.includes('retail/application?token=') ||
      url.includes('/cr?t=') ||
      url.includes('/cr?token=') ||
      url.includes(this.TOKEN_ACTION_ROUTES) ||
      url.includes(`/${CouponRequestStateService.EXPIRED_LINK_ROUTE}`) ||
      url.includes(`/${CouponRequestStateService.FINISHED_LINK_ROUTE}`) ||
      url.includes(`/${CouponRequestStateService.ERRORED_LINK_ROUTE}`)
    );
  }

  isNonAuthRoute(url: string): boolean {
    return !!this.NON_AUTH_ROUTES.find(route => route.includes(url));
  }

  AGGREGATES_SERVICE = {
    claimsStats: 'patient-assistance/requests/stats',
    paidClaimsStats: 'patient-assistance/paid-claim/stats',
    paidClaimSumStats: 'patient-assistance/paid-claim/sum-stats',
    patientAssistanceRequestsHistory: 'patient-assistance/requests/history',
    patientAssistanceRequestsSystem: 'patient-assistance/requests/system',
    pharmacyScorecardUrl: 'pharmacy/scorecard',
    pharmacyScorecardPerformance: 'pharmacy/scorecard/performance',
    pharmacyScorecardHistory: 'pharmacy/scorecard/history',
    pharmacyScorecardNetworkHistory: 'pharmacy/scorecard/network-history',
    pharmacyScorecardLatestClaims: 'pharmacy/scorecard/claims',
    pharmacyScorecardTopPrescribers: 'pharmacy/scorecard/prescribers',
    pharmacyScorecardTopPlans: 'pharmacy/scorecard/plans',
    pharmacyRefillRatio: 'pharmacy/scorecard/refill-ratio',
    pharmacyScorecardRegionClaims: 'pharmacy/scorecard/regions',
    statementsSumStats: 'statements/sum-stats',
  };

  RX_TRIAGE_SERVICE = {
    triageMetaInfo: 'triage-meta-information/{dateFrom}/{dateTo}?tenant={tenant}',
    invoke: 'invoke-triage-prescriptions/{from}/{to}/{version}?email={email}&tenant={tenant}',
  };

  TRIAGE_DELTA_SERVICE = {
    deltas: 'pharmacies-deltas-by-date-range/{dateFrom}/{dateTo}/{compare}?tenant={tenant}',
    triageCases: 'triage-cases-by-date-range/{dateFrom}/{dateTo}/{compare}?tenant={tenant}&listAll={listAll}',
    exportTriageCases: 'export/triage-cases-by-date-range/{dateFrom}/{dateTo}/{compare}?listAll={listAll}&tenant={tenant}',
  };

  COPAY_DELTA_SERVICE = {
    copayRequests: 'copay-requests-by-date-range/{dateFrom}/{dateTo}/{compare}?tenant={tenant}&listAll={listAll}&limit={limit}',
    exportCopayRequests: 'export/copay-requests-by-date-range/{dateFrom}/{dateTo}/{compare}?listAll={listAll}&tenant={tenant}',
  };

  TABLE_EDITOR_SERVICE = {
    tableSchemas: 'table-schemas/{tenant}',
    getTableData: 'mappings/table/{tableName}?tableSchemaVersion={tableSchemaVersion}' + '&version={version}&tenant={tenant}',
    getTableDataChanges:
      'mappings/table/{tableName}/changes/{startDate}/{endDate}?tableSchemaVersion={tableSchemaVersion}' + '&limit={limit}&tenant={tenant}',
    exportTableData: 'export/mappings/table/{tableName}/versions/{version}?tableSchemaVersion={tableSchemaVersion}&tenant={tenant}',
    exportTableDataChanges:
      'export/mappings/table/{tableName}/changes/{startDate}/{endDate}?tableSchemaVersion={tableSchemaVersion}' +
      '&limit={limit}&tenant={tenant}',
    getTableItem: 'mappings/table/{tableName}/{itemId}/versions/{version}?tableSchemaVersion={tableSchemaVersion}&tenant={tenant}',
    getTableItemVersions: 'mappings/table/{tableName}/{itemId}/versions?tableSchemaVersion={tableSchemaVersion}&tenant={tenant}',
    postTableData:
      'mappings/table/{tableName}?tableSchemaVersion={tableSchemaVersion}&versionGroup={versionGroup}' +
      '&version={version}&tenant={tenant}',
    discardTableItem:
      'mappings/discard/table/{tableName}?tableSchemaVersion={tableSchemaVersion}&versionGroup={versionGroup}' +
      '&version={version}&tenant={tenant}',
    versions: 'versions/{group}?tenant={tenant}',
    versionsCreate: 'versions/open/{group}?tenant={tenant}',
    versionsPublish: 'versions/publish/{versionId}?versionGroup={versionGroup}&tenant={tenant}',
    versionsDiscardChanges: 'versions/discard-changes/{version}?versionGroup={versionGroup}&tenant={tenant}',
    exportAllTablesChanges: 'export/mappings/version-groups/{versionGroup}/changes?tenant={tenant}&tableSchemaVersion={tableSchemaVersion}',
  };
}
